import './modal.css';
import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';

const Modal = (props) => {
    const closeOnEscapeKeyDown = (e) => { 
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    }

    useEffect(() => { 
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        }
    }, []);

    return ReactDOM.createPortal(
        <CSSTransition in={props.show}
            unmountOnExit timeout={{ enter: 0, exit: 300 }}>

            <div className={`modall ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="modall-content" onClick={e => e.stopPropagation()}>
                    <div className="modall-header">
                        <h4 className="modall-title">{props.title} </h4>
                    </div>
                    <div className="modall-body">
                        {props.children}
                    </div>
                    <div className="modall-footer">
                        <button className="button" onClick={props.onClose}>Close</button>
                    </div>
                </div>
            </div>
        </CSSTransition>, document.getElementById('root')
    );
};

export default Modal;