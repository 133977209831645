import { gql } from '@apollo/client';

export const ADD_TASK = gql`
    mutation addTask($dueDate: String, $taskName: String, $taskName2: String, $taskOwner: String!) {
        addTask(dueDate: $dueDate, taskName: $taskName, taskName2: $taskName2, taskOwner: $taskOwner) {
            dueDate
            taskName
            taskName2
            taskOwner
        }
    }
`;

export const DELETE_TASK = gql`
    mutation deleteTask($id: Int!) {
        deleteTask(id: $id) {
            dueDate
        }
    }
`;

export const ADD_USER = gql`
    mutation addUser($email: String!, $password: String!) {
        addUser(email: $email, password: $password) {
            token
            user {
                _id
                email
            }
        }
    }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        email
      }
    }
  }
`;