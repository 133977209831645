import { useMutation } from '@apollo/client';
import { DELETE_TASK } from '../../utils/mutations';
import './style.css'

const Task = ({ id, symbol, taskDueDate, taskName, taskName2 }) => {
    const [deleteTask, { error, data }] = useMutation(DELETE_TASK);

    const handleDelete = async () => { 
        
        try {
            await deleteTask({
                variables: {
                    id: id
                }
            });
        } catch (e) {
            console.error(e);
        }

        window.location.replace("/")
    }

    return (
        <div className="task-item">
            <img className="symbol" src={symbol} alt="test"></img> 
            <div className="duedate">{taskDueDate} </div>
            <div className="taskname">{taskName} </div>
            <div className="taskname2">{taskName2} </div>
            <button onClick={handleDelete}>Delete Task</button>
        </div>
    );
}

export default Task;