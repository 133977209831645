import { gql } from '@apollo/client';

export const QUERY_TASKS = gql`
    query GetTasks($taskOwner: String!) {
        getTasks(taskOwner: $taskOwner) {
            dueDate
            taskName
            taskName2
            id
            taskOwner
        }
    }
`;

export const QUERY_USER_BY_EMAIL = gql`
    query getUser($email: String!) {
        user(email: $email) {
            email
        }
    }
`;