import './signin.css';
import Modal from '../Modal/index';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../../utils/mutations';
import Register from '../Register/index';
import Auth from '../../utils/auth';

const SignIn = () => {
    
    const [show, setShow] = useState(false);
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [login, { error, data }] = useMutation(LOGIN_USER);

    // update state based on form input changes
    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });
    };
    // submit form
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const { data } = await login({
                variables: { ...formState },
            });

            Auth.login(data.login.token);
            // localStorage.setItem('emailID', data.login.user.email)
        } catch (e) {
            console.error(e);
        }

        // clear form values
        setFormState({
            email: '',
            password: '',
        });
    };

    
    return (
        <div className="signin">
            <form onSubmit={handleFormSubmit}>
                <h1>Sign In</h1>
                <input type="email" placeholder="email"
                    name="email" value={formState.email}
                    onChange={handleChange} />
                <input type="password" placeholder="password"
                    name="password" value={formState.password}
                    onChange={handleChange} />

                <button
                    id="signup-btn"
                    style={{ cursor: 'pointer' }}
                    type="submit"
                >
                    Login
                </button>


            </form>
            <button onClick={() => setShow(true)} className="register">Register</button>
            <Modal onClose={() => setShow(false)} show={show}
                title={"Register New User"} > <Register /> </Modal>
        </div>
    );
}

export default SignIn;