import decode from 'jwt-decode';
import Cookie from './cookieUtils';

class AuthService {
    getProfile() {
        try {
            return decode(this.getToken());
        } catch (e) {
            console.error(e);
        }
        
    }

    loggedIn() {
        const token = this.getToken();
        // If there is a token and its not expired, return `true`
        return token && !this.isTokenExpired(token) ? true : false;
    }

    isTokenExpired(token) {
        // Decode the token to get its expiration time that was set by the server
        const decoded = decode(token);
        // If the expiration time is less than the current time (in seconds),
        // the token is expired and we return 'true'
        if (decoded.exp < Date.now() / 1000) {
            // localStorage.removeItem('id_token');
            // deleteCookie('id_token');
            Cookie.deleteCookie('id_token');
            return true;
        }
        // If tokn hasn't passed its expiration time, return 'false'
        return false;
    }

    getToken() {
        // return getCookie('id_token');  // localStorage.getItem('id_token');
        return Cookie.getCookie('id_token');
    }

    login(idToken) {
        // localStorage.setItem('id_token', idToken);
        // setCookie('id_token', idToken, 0.02);
        Cookie.setCookie('id_token', idToken, 0.2)
        window.location.assign('/');
    }

    logout() {
        // localStorage.removeItem('id_token');
        // deleteCookie('id_token');
        Cookie.deleteCookie('id_token');
        window.location.assign('/');
    }

}

export default new AuthService();