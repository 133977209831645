import { useQuery } from '@apollo/client';
import { QUERY_TASKS } from '../../utils/queries';
import Logo from './logo192.png';
import Task from './Task';
import Auth from '../../utils/auth';

const TaskList = () => {
    const email = Auth.getProfile().data.email;
    
    const { loading, error, data } = useQuery(QUERY_TASKS, { variables: { taskOwner: email } });
    if (loading) { return (<div></div>) }

    var taskList = data.getTasks;
    
    
    var tasks = [];
    var sortedTasks = [];

    if (taskList) {
        sortedTasks = taskList.toSorted((a, b) => {
            return new Date(a.dueDate) - new Date(b.dueDate);
        });

        for (let i = 0; i < sortedTasks.length; i++) {
            tasks.push(<Task key={i + 1} id={sortedTasks[i].id} symbol={Logo}
                taskName={sortedTasks[i].taskName}
                taskName2={sortedTasks[i].taskName2}
                taskDueDate={sortedTasks[i].dueDate} />)

        }
    }



    return (
        <>
            {tasks}
        </>
    );
}

export default TaskList;