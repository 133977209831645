import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_TASK } from '../../utils/mutations';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from '../../components/Modal/index';
import './style.css';
import Auth from '../../utils/auth';

const Header = () => {
    const [show, setShow] = useState(false);
    const [formState, setFormState] = useState({ dueDate: '', taskName: '', taskName2: '' });
    const [addTask, { error, data }] = useMutation(ADD_TASK);
    var duedate = '';
    var taskname = '';
    const email = Auth.getProfile().data.email;

    const logout = (e) => {
        e.preventDefault();
        Auth.logout();
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });

    }

    const handleFormSubmit = async () => {

        try {
            await addTask({
                variables: {
                    dueDate: formState.dueDate,
                    taskName: formState.taskName,
                    taskName2: formState.taskName2,
                    taskOwner: email
                }
            });
        } catch (e) {
            console.error(e);
        }

        // setFormState({
        //     dueDate: '',
        //     taskName: ''
        // });
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    return (
        <div className="header">
            <Modal onClose={() => setShow(false)} show={show}
                title={"Add new task"}>

                <form onSubmit={handleFormSubmit}>
                    <div className="add-symbol"></div>
                    <div className="add-date">
                        <label>Due Date</label>
                        <input name="dueDate" type="date"
                            className="due-date" value={formState.dueDate}
                            onChange={handleChange}></input>
                    </div>
                    <div className="add-task">
                        <label>Task Name</label>
                        <input name="taskName" type="text"
                            className="task-name" value={formState.taskName}
                            onChange={handleChange}></input>
                    </div>
                    <div className="add-task2">
                        <label>Task Name2</label>
                        <input name="taskName2" type="text"
                            className="task-name2" value={formState.taskName2}
                            onChange={handleChange}></input>
                    </div>
                    <button type="submit">Save Task</button>
                </form>
            </Modal>

            <div className="header-container">
                <button onClick={logout} className="header-button">Logout</button>
                <h1>OnusTask</h1>
                <button onClick={handleShow} className="header-button">Add</button>
            </div>



        </div>
    )
}

export default Header;